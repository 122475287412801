<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">举报统计</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<div class="d-flex align-items-center m-1">
						<div class="label ">单位：</div>
						<el-select @change="handleFirstChange" v-model="first_id" placeholder="请选择支队">
							<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
								:value="item.unit_id">
							</el-option>
						</el-select>
						<el-select @change="handleSecondChange" v-model="second_id" placeholder="请选择大队">
							<el-option label="请选择" :key="0" :value="0">
							</el-option>
							<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
								:value="item.unit_id">
							</el-option>
						</el-select>
						<el-select v-model="third_id" placeholder="请请选择街道">
							<el-option label="请选择" :key="0" :value="0"></el-option>
							<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
								:value="item.unit_id"></el-option>
						</el-select>
					</div>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
					<el-button @click="handleExport" class="ml-2" type="primary" size="mini">导出</el-button>
				</div>
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="群众举报" name="report">
					<el-table border class="mt-3" :data="reportLists" style="width: 100%"
						:default-expand-all="true">
						<el-table-column align="center" label="单位">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
								<template v-if="scope.row.second_unit_name != '' && first_id > 0">
									<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
								</template>
								<template v-if="scope.row.third_unit_name != '' && second_id > 0">
									<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
								</template>
							</template>
						</el-table-column>
						<el-table-column align="center" label="未处理数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.untreated_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="已查实数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.verify_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="已整改数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.change_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="已反馈数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.feedback_count}}</span>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="执法检查" name="place_report">
					<el-table border class="mt-3" :data="placeReportLists" style="width: 100%"
						:default-expand-all="true">
						<el-table-column align="center" label="单位">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
								<template v-if="scope.row.second_unit_name != '' && first_id > 0">
									<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
								</template>
								<template v-if="scope.row.third_unit_name != '' && second_id > 0">
									<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
								</template>
							</template>
						</el-table-column>
						<el-table-column align="center" label="未处理数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.untreated_count}}</span>
							</template>
						</el-table-column>
						<!-- <el-table-column align="center" label="已查实数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.verify_count}}</span>
							</template>
						</el-table-column> -->
						<el-table-column align="center" label="已整改数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.change_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="住人数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.person_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="无住人数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.not_person_count}}</span>
							</template>
						</el-table-column>
						<!-- <el-table-column align="center" label="已反馈数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.feedback_count}}</span>
							</template>
						</el-table-column> -->
						<el-table-column align="center" label="开设逃生通道口数量">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.exits_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="落实防火分隔（处）">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.change_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="安装独立烟感（个）">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.fireproofing_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="配备防毒面罩（个）">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.defense_count}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="开展宣传培训（人）">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.train_count}}</span>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	import appMap from '@/components/app-map.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText,
			appMap,
		},
		data() {
			return {
				reportLists: [],
				placeReportLists: [],
				activeName: 'report',
				firstList: [],
				secondList: [],
				thirdList: [],
				first_id: 0,
				second_id: 0,
				third_id: 0,
				reportSearch: {
					s: 'store/statistics/report',
					page: 1,
				},
				placeReportSearch: {
					s: 'store/statistics/placeReport',
					page: 1,
				},
			}
		},
		created() {
			this.getUnitList();
		},
		methods: {
			handleExport() {
				if (this.activeName == 'report') {
					this.axios({
						token: true,
						params: {
							s: 'store/Statistics/reportExport',
							first_id: this.first_id,
							second_id: this.second_id,
							third_id: this.third_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							window.location.href = this.$conf.base_url + 'report.xls'
						}
					}).catch(err => {})
				}
				if (this.activeName == 'place_report') {
					this.axios({
						token: true,
						params: {
							s: 'store/Statistics/placeReportExport',
							first_id: this.first_id,
							second_id: this.second_id,
							third_id: this.third_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							window.location.href = this.$conf.base_url + 'place_report.xls'
						}
					}).catch(err => {})
				}
			},
			handleClick(res) {
				if (res.name == 'report') {
					this.reportGetList();
				}
				if (res.name == 'place_report') {
					this.placeReportGetList();
				}
			},
			toSearch() {
				if (this.activeName == 'report') {
					this.reportGetList();
				}
				if (this.activeName == 'place_report') {
					this.placeReportGetList();
				}
			},
			handleFirstChange(first_id) {
				this.second_id = 0;
				this.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
			},
			handleSecondChange(second_id) {
				this.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data
					this.first_id = res.data.data[0].unit_id
					this.secondList = res.data.data[0].child
					this.reportGetList();
				}).catch(err => {});
			},
			reportGetList() {
				this.layout.showLoading()
				let params = this.reportSearch;
				params.first_id = this.first_id
				params.second_id = this.second_id
				params.third_id = this.third_id
				this.axios({
					token: true,
					params: params,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.reportLists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			placeReportGetList() {
				this.layout.showLoading()
				let params = this.placeReportSearch;
				params.first_id = this.first_id
				params.second_id = this.second_id
				params.third_id = this.third_id
				this.axios({
					token: true,
					params: params,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.placeReportLists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>

</style>
